<template >
  <section>
    <hero-global
        first-width="75"
        last-width="25"
        title="Bizum a CERMI"
        subtitle="Bizum a CERMI Estatal"
        breadcrumb="Bizum a CERMI Estatal"
        img="/img/bizum/bizum_1.svg" />
  </section>
  <section class="container p-2-rem bizum">
    <div>
      <h2 class="subtitle text-center">Donativos vía Bizum a CERMI Estatal</h2>
    </div>
    <br/>
    <br/>
    <br/>
    <div class="grid-2">
      <div class="grid">
        <ol>
          <li class="font-family-roboto font-size-16 font-light"> Entrar en la aplicación de banca móvil: BIZUM.</li>
          <li class="font-family-roboto font-size-16 font-light"> Seleccionar “Enviar ONG”.</li>
          <li class="font-family-roboto font-size-16 font-light"> Introducir el código del Comité Español de Representantes de Personas con Discapacidad (CERMI): 03851 (también se puede buscar con el nombre completo de la entidad).</li>
          <li class="font-family-roboto font-size-16 font-light"> Seleccionar: importe objeto de donativo y cuenta de cargo. Introducidas las claves, aparecerá un mensaje de confirmación y el cargo en la cuenta como “BIZUM a favor del Comité Español de Representantes de Personas con Discapacidad”.</li>
          <li class="font-family-roboto font-size-16 font-light">
            <div class="d-flex-column">
              <p class="m-y-1-rem">Para solicitar el certificado de donaciones es necesario enviar un correo a cermi@cermi.es con los siguientes datos:</p>
              <p class="">(Asunto: Pago BIZUM)</p>
              <ul class="p-1-rem">
                <li>Nombre y apellidos, o razón social.</li>
                <li>DNI u otro documento oficial de identidad.</li>
                <li>Dirección postal.</li>
                <li>Correo electrónico.</li>
                <li>Teléfono móvil desde el que se ha efectuado el BIZUM.</li>
                <li>Importe donado a través de BIZUM.</li>
              </ul>
            </div>
          </li>
        </ol>
        <a class="font-family-roboto text-light text-primary font-size-16" href="/pdf/BENEFICIOS FISCALES CERMI.pdf" download>Informe marco de aspectos clave sobre la realización de donaciones al CERMI Estatal</a>
      </div>
      <div class="grid">
        <img src="/img/bizum/bizum_2.svg" alt="Imágen con Informativa sobre código BIZUM" title="Imágen con Informativa sobre código BIZUM" />
      </div>
    </div>
  </section>
  <br/>
  <br/>
  <section>
    <other-links-interest class="links-interest-box container"/>
  </section>
  <br/>
  <br/>
  <br/>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
export default {
  name: "Bizum",
  components: {OtherLinksInterest, HeroGlobal},
  data:()=>({
    data:{
      items:[
        'Entrar en la aplicación de banca móvil: BIZUM.'
      ]
    }
  })
}
</script>

<style scoped lang="scss">
@import "@/styles/colours.scss";

ol {
  list-style    : none;
  counter-reset : counter;
  &>li {
    counter-increment : counter;
    display           : flex;
    align-items       : center;
    width             : 80%;
    ul{
      list-style: disc;
    }
  }
  &>li::before {
    content          : counter(counter) " ";
    color            : $blueLight;
    font-weight      : bold;
    font-size        : 48px;
    padding          : .5rem;
    text-align       : right;
  }
}
</style>
